.TextView {
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    overflow-y: auto;
    transition: background-color 1s ease;
}

.DateText, .Text {
    transition: opacity 1s ease;
}

.DateText {
    margin-top: 16px;

    display: flex;
    flex-direction: row;
    justify-content: center;

    font-size: 16px;
    font-weight: 400;

    user-select: none;
    cursor: default;

    background-color: inherit;
    position: sticky;
    top: 0;

    padding: 8px;
}

.Text {
    margin: 36px 32px 100px 32px;
    display: flex;
    flex-direction: column;
    align-self: center;
    justify-content: center;
    flex: 1;
}

.Text * {
    word-break: break-word;
}
