@import "~normalize.css";
@import "~@blueprintjs/core/lib/css/blueprint.css";
@import "~@blueprintjs/datetime/lib/css/blueprint-datetime.css";
@import "~@blueprintjs/icons/lib/css/blueprint-icons.css";

html {
    height: 100%;
}

body {
    margin: 0;
    font-family: 'Padauk', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    font-style: normal;
    font-weight: 400;

    height: 100%;
}

#root {
    height: 100%;
}

.bp3-datepicker .DayPicker-Day, .DayPicker-Weekday {
    color: #212121;
}

.bp3-button-text {
    margin-top: 4px;
}
