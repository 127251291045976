.Editor {
    display: flex;
    flex-direction: row;
    height: 100%;
    justify-content: space-between;
}

.TextViewWrapper {
    display: flex;
    flex: 1;
    flex-direction: column;
}

.TextViewPreview {
    flex: 1;
    padding: 12px;
    max-height: 50%;
    overflow: auto;
    align-self: center;
}

.TextView {
    flex: 1;
    border: none;
    resize: none;
    outline: none;
    padding: 12px 12px 0;
    font-size: 18px;
}
