.PromptPassword {
    margin-top: 8px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.PromptPassword input {
    margin-right: 8px;
    border: 1px solid #ccc;
    border-radius: 2px;
}
