.Meter {
    height: 3px;
    position: fixed;
    background: #44ff17;
    overflow: hidden;
    top: 0;
    width: 100%;
    z-index: 1000;
}

.Progress {
    background-color: #34e417;
    animation: progressBar 500ms linear infinite;
}

.Meter span {
    display: block;
    height: 100%;
    width: 300px;
}

@keyframes progressBar {
    0% {
        margin-left: 0;
    }
    20% {
        margin-left: 20%;
    }
    40% {
        margin-left: 40%;
    }
    60% {
        margin-left: 60%;
    }
    80% {
        margin-left: 80%;
    }
    100% {
        margin-left: 100%;
    }
}
